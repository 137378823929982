import { CellId } from "@hex/common";
import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  CellMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext";

export interface UseCellsSelectorArgs<T> {
  selector: (cells: Record<CellId, CellMP | undefined>) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useCellsSelector<T>({
  equalityFn,
  selector,
}: UseCellsSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const cellsState = hexVersionMPSelectors
      .getCellSelectors(hexVersionId)
      .selectEntities(state);

    if (cellsState == null) {
      throw new Error(`Missing cells state for hex version: ${hexVersionId}`);
    }

    return selector(cellsState);
  }, equalityFn);
}

type SafeCellsMP<S extends boolean> = S extends false
  ? Record<CellId, CellMP | undefined>
  : Record<CellId, CellMP | undefined> | undefined;

export type UseCellsGetterArgs<
  ARGS extends unknown[],
  SAFE extends boolean,
  T,
> = {
  selector?: (cells: SafeCellsMP<SAFE>, ...args: ARGS) => T;
  /**
   * @default false
   */
  safe?: SAFE;
};

export type UseCellsGetterResult<ARGS extends unknown[], T> = (
  ...args: ARGS
) => T;

export function useCellsGetter<
  ARGS extends unknown[],
  SAFE extends boolean = false,
  T = Record<CellId, CellMP | undefined>,
>({
  safe,
  selector,
}: UseCellsGetterArgs<ARGS, SAFE, T> = {}): UseCellsGetterResult<ARGS, T> {
  const projectContext = useProjectContext({ safe: safe ?? false });
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: ARGS) => {
      const cells = projectContext?.hexVersionId
        ? hexVersionMPSelectors
            .getCellSelectors(projectContext.hexVersionId)
            .selectEntities(store.getState())
        : undefined;

      if (cells == null && !safe) {
        throw new Error(
          `Missing cells state for hex version: ${projectContext?.hexVersionId}`,
        );
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(cells as SafeCellsMP<SAFE>, ...args)
        : (cells as T);
    },
    [projectContext?.hexVersionId, store, safe, selectorRef],
  );
}
