import {
  Classes,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemProps,
} from "@blueprintjs/core";
import classNames from "classnames";
import { rgba } from "polished";
import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { HeadingFiveStyles } from "../components/Heading";

import { HexTooltip } from "./HexTooltip.js";

export const menuStyles = css<{ $scrollContent?: boolean }>`
  min-width: 80px;

  color: ${({ theme }) => theme.fontColor.DEFAULT};

  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};

  ${({ $scrollContent }) =>
    $scrollContent &&
    css`
      /* Blueprint popovers require a max height to allow scrolling and prevent overflow.
   https://blueprintjs.com/docs/#core/components/popover.sizing
   A non-hardcoded max here is preferred so we maintain a height that dynamically fits
   content when screen size suffices */
      max-height: 40vh;
      overflow-y: auto;
    `}
`;

/**
 * https://blueprintjs.com/docs/#core/components/menu
 */
export const HexMenu = styled(Menu)<{ $scrollContent?: boolean }>`
  ${menuStyles}

  .${Classes.MENU_SUBMENU} {
    &.${Classes.POPOVER} {
      background: transparent;
      box-shadow: none;
    }

    .${Classes.MENU} {
      background: ${({ theme }) => theme.backgroundColor.DEFAULT};
      box-shadow: ${({ theme }) => theme.boxShadow.POPOVER};
      ${menuStyles}
    }

    .${Classes.MENU_ITEM} {
      &:hover {
        background-color: ${({ theme }) => theme.hoverColor};
      }
    }

    .${Classes.POPOVER_TARGET}.${Classes.POPOVER_OPEN} > .${Classes.MENU_ITEM} {
      background-color: ${({ theme }) => theme.hoverColor};
    }
  }
`;

export const HexMenuDivider = styled(MenuDivider)`
  border-color: ${({ theme }) => theme.borderColor.MUTED};

  && {
    &.${Classes.MENU_HEADER} > .${Classes.HEADING} {
      ${HeadingFiveStyles}
    }
  }
`;

export const HexMenuFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px 7px;

  font-size: ${({ theme }) => theme.fontSize.SMALL};

  border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};
`;

const RowContainer = styled.span`
  display: flex;

  flex: auto;
  align-items: center;

  white-space: normal;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.span<{ $small?: boolean; $textColor?: string }>`
  min-height: 20px;

  color: ${({ $textColor, theme }) => $textColor ?? theme.fontColor.DEFAULT};
  font-size: ${({ $small, theme }) =>
    $small ? theme.fontSize.SMALL : theme.fontSize.DEFAULT};
`;

const Description = styled.span<{ $small?: boolean }>`
  margin-top: ${({ $small }) => ($small ? "1px" : "2px")};

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 16px;
`;

const TextContainer = styled.span`
  flex: auto;

  ${Description} {
    display: block;
  }

  &.${Classes.ACTIVE}, &.${Classes.INTENT_PRIMARY}.${Classes.ACTIVE} {
    ${Name} {
      color: ${({ theme }) => theme.menuItem.activeText};
    }
    ${Description} {
      color: ${({ theme }) => rgba(theme.menuItem.activeText, 0.6)};
    }
  }
`;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- calling attrs is okay
export const HexMenuItem = styled(MenuItem).attrs({
  popoverProps: { hoverCloseDelay: 200 } as MenuItemProps["popoverProps"], // type hack so downstream types are still correct
})<{
  $keyboardActive?: boolean;
  $align?: "center" | "start";
  $small?: boolean;
  $mono?: boolean;
}>`
  align-items: ${({ $align }) =>
    $align === "start" ? "flex-start" : "center"};
  min-height: 30px;
  border-radius: ${({ theme }) => theme.borderRadius};

  transition:
    background-color ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing},
    color ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing};

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }

  &:active {
    background-color: ${({ theme }) => theme.hoverColor};
  }

  ${({ $small }) =>
    $small &&
    css`
      min-height: 24px;
      padding: 2px 5px;
      font-size: ${({ theme }) => theme.fontSize.SMALL};
    `}

  ${({ $mono, theme }) =>
    $mono &&
    css`
      font-family: ${theme.fontFamily.MONO};
    `};

  &.${Classes.ACTIVE},
    &.${Classes.ACTIVE}:hover,
    &.${Classes.INTENT_PRIMARY}.${Classes.ACTIVE},
    &.${Classes.INTENT_PRIMARY}.${Classes.ACTIVE}:hover {
    color: ${({ theme }) => theme.menuItem.activeText};

    background-color: ${({ theme }) => theme.menuItem.activeBackground};
    border-radius: ${({ theme }) => theme.borderRadius};

    .${Classes.MENU_ITEM_LABEL} {
      color: ${({ theme }) => theme.menuItem.activeText};
    }

    > .${Classes.MENU_ITEM_ICON} .${Classes.ICON} svg {
      color: ${({ theme }) => theme.menuItem.activeText};
    }
  }

  &.${Classes.INTENT_DANGER} {
    color: ${({ theme }) => theme.intent.DANGER};

    > .${Classes.MENU_ITEM_LABEL} {
      color: ${({ theme }) => theme.intent.DANGER};
    }

    > .${Classes.MENU_ITEM_ICON} .${Classes.ICON} svg {
      color: ${({ theme }) => theme.intent.DANGER};
    }

    &:hover {
      color: ${({ theme }) => theme.intent.DANGER};

      background-color: ${({ theme }) => rgba(theme.intent.DANGER, 0.2)};

      > .${Classes.MENU_ITEM_LABEL} {
        color: ${({ theme }) => theme.intent.DANGER};
      }
    }
  }

  ${({ $keyboardActive }) =>
    $keyboardActive &&
    css`
      background-color: ${({ theme }) => theme.hoverColor};
    `}

  > .${Classes.ICON} {
    margin-top: ${({ $align }) => ($align === "start" ? "2px" : "0")};
  }

  > .${Classes.ICON} > svg {
    color: inherit;
  }

  > .${Classes.MENU_ITEM_LABEL} {
    display: flex;
    align-items: center;
    padding-left: 6px;

    > .${Classes.ICON} > svg {
      color: inherit;
    }
  }

  & .${Classes.DISABLED} {
    ${Name}, ${Description} {
      color: ${({ theme }) => theme.fontColor.PLACEHOLDER};
    }
  }

  &.${Classes.DISABLED} {
    .${Classes.MENU_ITEM_LABEL} {
      opacity: 0.5;
    }
  }
`;
interface TextRendererProps {
  active?: boolean;
  name: ReactNode;
  description?: ReactNode;
  rightElement?: ReactNode;
  disabled?: boolean;
  small?: boolean;
  rightAction?: ReactNode;
  ellipsize?: boolean;
  textColor?: string;
}

export const BigMenuItemTextRenderer: React.ComponentType<TextRendererProps> =
  React.memo(function TextRenderer({
    active = false,
    description,
    disabled = false,
    ellipsize = false,
    name,
    rightAction,
    rightElement,
    small,
    textColor,
  }) {
    const classes = classNames({
      [Classes.ACTIVE]: active,
      [Classes.DISABLED]: disabled,
      [Classes.TEXT_OVERFLOW_ELLIPSIS]: ellipsize,
    });

    return (
      <RowContainer>
        <TextContainer className={classes}>
          <Top>
            <HexTooltip
              content={name}
              css={css`
                && {
                  width: 100%;
                  display: flex;
                }
              `}
              disabled={!ellipsize}
            >
              <Name
                $small={small}
                className={ellipsize ? Classes.TEXT_OVERFLOW_ELLIPSIS : ""}
                $textColor={textColor}
              >
                {name}
              </Name>
            </HexTooltip>
            {rightElement}
          </Top>
          {description && (
            <Description $small={small}>{description}</Description>
          )}
        </TextContainer>
        {rightAction}
      </RowContainer>
    );
  });

export const BigMenuItem = styled(HexMenuItem)`
  width: 205px;
  padding: 7px 10px;

  &,
  &:hover {
    color: ${({ theme }) => theme.fontColor.MUTED};
  }

  > .${Classes.ICON} {
    margin: 0 8px 0 0;
  }

  .${Classes.ICON} {
    flex: none;

    text-align: center;

    svg {
      display: inline;
    }
  }

  & .${Classes.DIVIDER} {
    margin: 5px;
  }

  &.${Classes.ACTIVE} {
    .${Classes.KEY_COMBO} {
      border-color: ${({ theme }) => rgba(theme.menuItem.activeText, 0.2)};
    }
  }

  .${Classes.MENU_ITEM_LABEL} {
    .${Classes.KEY_COMBO} {
      position: absolute;

      top: 2px;
      right: 0;

      justify-content: center;

      height: 18px;

      .${Classes.ICON} {
        display: inline-flex;
        margin: 0;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
    position: relative;

    width: 22px;
  }
`;
