import {
  AirlockBlockConfig,
  BlockCellId,
  BlockConfig,
  CellId,
  SQLCellBlockConfig,
  guardNever,
  notEmpty,
  stableEmptyArray,
  typedObjectValues,
} from "@hex/common";
import { useCallback, useMemo } from "react";
import { shallowEqual } from "react-redux";
import { createSelector } from "reselect";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  CellContentsMP,
  CellMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext";
import { BlockCellMpFragment } from "../HexVersionMPModel.generated.js";

import { useCellsContentsSelector } from "./cellsContentsStateHooks.js";
import { useCellSelector } from "./cellStateHooks";

export interface UseParentBlockCellContents<T> {
  cellId: CellId;
  selector: (cellContents: BlockCellMpFragment & { cellId: CellId }) => T;
}

export function useParentBlockCellContents<T>(
  args: UseParentBlockCellContents<T>,
): T | null {
  const { hexVersionId } = useProjectContext();
  const parentBlockCellId = useCellSelector({
    safe: true,
    cellId: args.cellId,
    selector: (cell) => cell?.parentBlockCellId,
  });

  return useSelector((state) => {
    if (parentBlockCellId == null) {
      return null;
    }

    const parentBlockCellContents = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellContentsId(state, parentBlockCellId);

    if (
      parentBlockCellContents?.__typename !== "BlockCell" ||
      parentBlockCellContents.blockConfig == null
    ) {
      return null;
    }

    return args.selector(parentBlockCellContents);
  });
}

export interface useBlockCellContentsGetterArgs<T> {
  selector: (cellMP: CellContentsMP) => T;
}

export function useBlockCellContentsGetter<T>(
  args: useBlockCellContentsGetterArgs<T>,
): (blockCellId: BlockCellId) => T | null {
  const { hexVersionId } = useProjectContext();
  const store = useStore();
  const selectorRef = useStableRef(args.selector);

  return useCallback(
    (blockCellId: BlockCellId) => {
      const state = store.getState();

      const parentBlockCellContents = hexVersionMPSelectors
        .getCellContentSelectors(hexVersionId)
        .selectByCellContentsId(state, blockCellId);

      if (
        parentBlockCellContents?.__typename !== "BlockCell" ||
        parentBlockCellContents.blockConfig == null
      ) {
        return null;
      }

      return selectorRef.current(parentBlockCellContents) ?? null;
    },
    [hexVersionId, selectorRef, store],
  );
}

export interface UseAirlockParentCellArgs<T> {
  cellId: CellId;
  selector?: (cellMP: CellMP) => T;
}

export function useAirlockParentCell<T>(
  args: UseAirlockParentCellArgs<T>,
): T | null {
  const { hexVersionId } = useProjectContext();
  const parentBlockCellId = useCellSelector({
    safe: true,
    cellId: args.cellId,
    selector: (cell) => cell?.parentBlockCellId,
  });

  return useSelector((state) => {
    if (parentBlockCellId == null) {
      return null;
    }

    const parentCellId =
      state.hexVersionMP[hexVersionId]?.cellContentsIdToCellId[
        parentBlockCellId
      ];

    if (!parentCellId) {
      return null;
    }

    const val =
      state.hexVersionMP[hexVersionId]?.cells.entities[parentCellId] ?? null;
    return val == null
      ? null
      : args.selector
        ? args.selector?.(val)
        : (val as unknown as T);
  }, shallowEqual);
}

/**
 * Find all of the cellIds that are rooted in a BlockCell that are currently not the active view type.
 */
export function useBlockCellHiddenCellIds(): Set<CellId> {
  const cellsContents = useCellsContentsSelector({
    selector: (cellsContentsState) => cellsContentsState,
  });
  const sqlCellBlockCells = selectSqlCellBlockCellIds(cellsContents);

  return useMemo(() => {
    const hiddenCellIds = new Set<CellId>();
    sqlCellBlockCells.forEach((blockCell) => {
      const cellId = blockCell.cellId;
      const cellContentsForBlockCell = cellsContents[cellId];
      if (
        cellContentsForBlockCell?.__typename === "BlockCell" &&
        SQLCellBlockConfig.guard(cellContentsForBlockCell.blockConfig)
      ) {
        const viewType = cellContentsForBlockCell.blockConfig.activeTab;
        switch (viewType) {
          case "preview":
          case "display":
            // if the view type is for a table, the chart is hidden.
            hiddenCellIds.add(cellContentsForBlockCell.blockConfig.chartCellId);
            break;
          case "chart":
            // if the view type is for a chart, we should still show the sql cell source
            // because it will appear as part of the cell (even though not the active view)
            break;
          default:
            guardNever(viewType, viewType);
        }
      }
    });
    return hiddenCellIds;
  }, [cellsContents, sqlCellBlockCells]);
}

export interface useBlockChildCellSelectorArgs<T> {
  blockCellId: BlockCellId;
  selector?: (cell: CellMP[] | undefined) => T;
}

export function useBlockChildCellSelector<T>(
  args: useBlockChildCellSelectorArgs<T>,
): T {
  const { hexVersionId } = useProjectContext();
  const selector = args.selector;

  return useSelector((state) => {
    const cellIds = hexVersionMPSelectors
      .getBlockCellSelectors(hexVersionId)
      .getBlockChildCellIds(state, args.blockCellId);

    const cellsMap = hexVersionMPSelectors
      .getCellSelectors(hexVersionId)
      .selectEntities(state);

    const allCells = cellIds
      .map((cellId) => cellsMap?.[cellId])
      .filter(notEmpty);

    return selector ? selector(allCells) : (allCells as unknown as T);
  }, shallowEqual);
}

export function useBlockChildCellsIdGetter(): (
  blockCellId: BlockCellId,
) => CellId[] {
  const { hexVersionId } = useProjectContext();
  const store = useStore();

  return useCallback(
    (blockCellId: BlockCellId) => {
      const cellIds = hexVersionMPSelectors
        .getBlockCellSelectors(hexVersionId)
        .getBlockChildCellIds(store.getState(), blockCellId);

      return cellIds;
    },
    [hexVersionId, store],
  );
}

export function useParentBlockCellConfig(cellId: CellId): BlockConfig | null {
  return useParentBlockCellContents({
    cellId,
    selector: (cellContents) => {
      return cellContents.blockConfig;
    },
  });
}

export function useParentBlockAirlockConfig(
  cellId: CellId,
): AirlockBlockConfig | null {
  const config = useParentBlockCellContents({
    cellId,
    selector: (cellContents) => {
      return cellContents.blockConfig;
    },
  });

  return AirlockBlockConfig.guard(config) ? config : null;
}

export function useBlockAirlockConfigGetter(): (
  cellId: BlockCellId,
) => AirlockBlockConfig | null {
  const store = useStore();
  const { hexVersionId } = useProjectContext();

  return useCallback(
    (blockCellId: BlockCellId) => {
      const state = store.getState();
      const contents = hexVersionMPSelectors
        .getCellContentSelectors(hexVersionId)
        .selectByCellContentsId(state, blockCellId);

      return contents?.__typename === "BlockCell" &&
        AirlockBlockConfig.guard(contents.blockConfig)
        ? contents.blockConfig
        : null;
    },
    [hexVersionId, store],
  );
}

export function useSqlCellBlockConfig(
  cellId: CellId,
): SQLCellBlockConfig | null {
  const config = useParentBlockCellContents({
    cellId,
    selector: (cellContents) => {
      return cellContents.blockConfig;
    },
  });

  return SQLCellBlockConfig.guard(config) ? config : null;
}

export function useAirlockBlockConfig(
  cellId: CellId,
): AirlockBlockConfig | null {
  const config = useParentBlockCellContents({
    cellId,
    selector: (cellContents) => {
      return cellContents.blockConfig;
    },
  });

  return AirlockBlockConfig.guard(config) ? config : null;
}

export function useSqlCellBlockConfigFromParent(
  parentCell: BlockCellMpFragment | null,
): SQLCellBlockConfig | null {
  if (parentCell == null) {
    return null;
  }
  return SQLCellBlockConfig.guard(parentCell.blockConfig)
    ? parentCell.blockConfig
    : null;
}

export interface UseSiblingChartCellSelectorArgs<T> {
  cellId: CellId;
  selector: (cell: CellMP) => T;
}

export function useSiblingChartCellSelector<T>(
  args: UseSiblingChartCellSelectorArgs<T>,
): T | null {
  const parentBlockConfig = useSqlCellBlockConfig(args.cellId);

  const siblingChartCell = useCellSelector({
    cellId: parentBlockConfig?.chartCellId,
    safe: true,
    selector: (cell: CellMP | undefined) => cell,
  });

  return useSelector(() => {
    if (siblingChartCell == null) {
      return null;
    }

    return args.selector(siblingChartCell);
  });
}
const selectSqlCellBlockCellIds = createSelector(
  (cellContents: Record<CellId, CellContentsMP | undefined>) => cellContents,
  (cellContents) => {
    return typedObjectValues(cellContents)
      .filter(notEmpty)
      .filter(
        (cell) =>
          cell.deletedDate == null &&
          cell.__typename === "BlockCell" &&
          cell.blockCellId != null &&
          SQLCellBlockConfig.guard(cell.blockConfig),
      );
  },
);

const selectAirlockCellIds = createSelector(
  (cellContents: Record<CellId, CellContentsMP | undefined>) => cellContents,
  (cellContents) => {
    return typedObjectValues(cellContents)
      .filter(notEmpty)
      .filter(
        (cell) =>
          cell.deletedDate == null &&
          cell.__typename === "BlockCell" &&
          cell.blockCellId != null &&
          AirlockBlockConfig.guard(cell.blockConfig),
      );
  },
);

export function useAirlockCellIds(): readonly CellId[] {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const cellContents = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectEntities(state);

    if (!cellContents) {
      return stableEmptyArray();
    }

    const airlockCells = selectAirlockCellIds(cellContents);
    const ids = airlockCells.map((cell) => cell.cellId);

    return ids;
  }, shallowEqual);
}

export function useAirlockBlockCellIds(): readonly BlockCellId[] {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const cellContents = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectEntities(state);

    if (!cellContents) {
      return stableEmptyArray();
    }

    const airlockCells = selectAirlockCellIds(cellContents);
    const ids = airlockCells.flatMap((cell) =>
      cell.__typename === "BlockCell" ? cell.blockCellId : [],
    );

    return ids;
  }, shallowEqual);
}

export function useAirlockCellIdsGetter(): () => readonly CellId[] {
  const { hexVersionId } = useProjectContext();
  const store = useStore();

  return useCallback(() => {
    const cellContents = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectEntities(store.getState());

    if (!cellContents) {
      return stableEmptyArray();
    }

    const airlockCells = selectAirlockCellIds(cellContents);
    const ids = airlockCells.map((cell) => cell.cellId);

    return ids;
  }, [hexVersionId, store]);
}
